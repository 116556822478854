import React, {lazy, Suspense, useEffect} from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";

const RoutingComponent = lazy(() => import("./components/Routing"));

const renderLoader = () => (
  <div className="relative flex justify-center items-center h-screen">
    <div className="inline-block animate-spin ease duration-300 w-10 h-10 bg-main_color mx-2"></div>
  </div>
);

function App() {
  useEffect(() => {
    ReactGA.initialize("G-9GC32QRD76");
    ReactGA.pageview(window.location.pathname );
  }, []);
  return (
    <div className="w-full dark:bg-white text-black">
      <Suspense fallback={renderLoader()}>
        <Router>
          <RoutingComponent />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
